.DivImg {
    max-width: 100%;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.DivImg img {
    width: 100%;
    /* height: 85%; */
}

.PaddingLeft {
    padding-left: 0;
}

.PaddingRight {
    padding-right: 0;
}

@media (min-width: 992px) {
    .PaddingLeft {
        padding-left: 8%;
    }

    .PaddingRight {
        padding-right: 8%;
    }
}

.contextJustify {
    text-align: justify;
}

@media (min-width: 992px) {
    #DisplayScreen {
        display: none;
    }
}