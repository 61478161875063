/* <span>Photo by <a href="https://unsplash.com/@sandeepdelineates?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">sandeep gill</a> on <a href="https://unsplash.com/s/photos/university?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a></span> */

.Bg {
    background-image: url(../../assets/staff_pic/staff_group_pic.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    max-height: 550px;
    background-size: cover;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
}

.Head {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 3rem;
    margin-top: 200px;
}

.Bottom {
    margin-bottom: 150px;
}

.TitleFont {
    font-family: "Roboto Condensed", sans-serif;
}

.MarginLeft {
    margin-left: 0;
}

@media (min-width: 992px) {
    .Head {
        font-size: 4rem;
    }

    .MarginLeft {
        margin-left: 10%;
    }
}

/* Animation */

@keyframes FadeInUp {
    from {
        transform: translate3d(0, 40px, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@-webkit-keyframes FadeInUp {
    from {
        transform: translate3d(0, 40px, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.Animated {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both;
}

.AnimatedChild {
    animation-delay: 0.3s;
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-delay: 0.3s;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both;
}

.AnimatedFadeInUp {
    opacity: 0;
}

.FadeInUp {
    opacity: 0;
    animation-name: FadeInUp;
    -webkit-animation-name: FadeInUp;
}

.flip-card {
    background-color: transparent;
    width: 300px;
    height: 300px;
    perspective: 1000px;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-card-front {
    background-color: #bbb;
    color: black;
  }
  
  .flip-card-back {
    background-color: #2980b9;
    color: white;
    transform: rotateY(180deg);
  }