.Img {
    position: absolute;
    margin: auto;
    top: 0px;
    bottom: 20px;
    left: 0;
    right: 0;
    height: 100%;
    width:  100%;
}

.LeftMar {
    margin-left: 22px;
}

@media (min-width: 992px) {
    .LeftMar {
        margin-left: 0px;
    }
}
