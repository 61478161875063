/* <span>Photo by <a href="https://unsplash.com/@scottwebb?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Scott Webb</a> on <a href="https://unsplash.com/s/photos/school-building?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a></span> */
.Bg {
    background-image: url(../../assets/img/facilities_image.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    max-height: 550px;
    background-size: cover;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
}

.Head {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 3rem;
    margin-top: 200px;
}

.Bottom {
    margin-bottom: 150px;
}

.TitleFont {
    font-family: "Roboto Condensed", sans-serif;
}

@media (min-width: 992px) {
    .Head {
        font-size: 4rem;
    }
}

/* Animation */

@keyframes FadeInUp {
    from {
        transform: translate3d(0, 40px, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@-webkit-keyframes FadeInUp {
    from {
        transform: translate3d(0, 40px, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.Animated {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both;
}

.AnimatedChild {
    animation-delay: 0.3s;
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-delay: 0.3s;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both;
}

.AnimatedFadeInUp {
    opacity: 0;
}

.FadeInUp {
    opacity: 0;
    animation-name: FadeInUp;
    -webkit-animation-name: FadeInUp;
}

.Dots {
    border-bottom: dotted 3px #24abee;
    max-width: 220px;
    margin: auto;
}

.FontColor {
    color: yellow;
}

@media (max-width: 992px) {
    #DisplayScreen {
        display: none;
    }
}